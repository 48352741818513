/* eslint-disable jsx-a11y/click-events-have-key-events */
import camelCase from 'lodash/camelCase'

import { EventType, GTM, ISocialShareData } from '@scm/tag-manager'
import { Icon } from '@scm/ui-component-icon'
import { Bem } from '@scm/ui-core'

import { PrintProps } from './type'

const print = Bem('SocialShare')

export const Print = (props: PrintProps) => {
  const { variant, className, location } = props
  const sendEvent = GTM.hooks.useSendEvent()
  const handleClickEvent = () => {
    const payload: ISocialShareData = {
      name: 'print',
      location: location,
    }
    sendEvent({
      data: payload,
      event: EventType.SOCIAL_SHARE,
      _clear: true,
    })
    window.print()
    return false
  }

  return (
    <div data-component="print" className={className}>
      <span
        onClick={handleClickEvent}
        role="button"
        aria-label="print"
        data-testid="print-button"
        tabIndex={0}
        className={print('IconWrapper', {
          variant: camelCase(variant),
        })}
      >
        <Icon
          icon="Printer"
          className={print('Icon', { variant: camelCase(variant) })}
        />
      </span>
    </div>
  )
}
