import camelCase from 'lodash/camelCase'

import { EventType, GTM, ISocialShareData } from '@scm/tag-manager'
import { Icon } from '@scm/ui-component-icon'
import { Link } from '@scm/ui-component-link'
import { Print } from '@scm/ui-component-print'
import { socialShare } from './socialShare.constants'
import { ClickEventArgs, IconSocialShareProps } from './type'

export const IconSocialShare = ({
  className,
  variant,
  classNameLink,
  icons,
  location,
}: IconSocialShareProps) => {
  if (!icons || !icons.length) return null
  const sendEvent = GTM.hooks.useSendEvent()
  const handleClickEvent = ({ event, type, target, url }: ClickEventArgs) => {
    event.preventDefault()
    const payload: ISocialShareData = {
      name: type,
      location: location,
    }
    sendEvent({
      data: payload,
      event: EventType.SOCIAL_SHARE,
      _clear: true,
    })
    window.open(url, target)
    return false
  }

  return (
    <div
      data-component="social-share"
      className={socialShare({ variant: camelCase(variant) }, [className])}
    >
      {icons.map(({ type, url }, index) => {
        if (type === 'Print') {
          return (
            <Print
              key={index}
              variant={variant}
              location={location}
              className={classNameLink}
            />
          )
        }
        if (url === '#') return null

        return (
          // eslint-disable-next-line jsx-a11y/anchor-is-valid
          <Link
            to={url}
            key={index}
            target="_blank"
            rel="noreferrer"
            aria-label={type}
            className={classNameLink}
            onClick={event => {
              const args: ClickEventArgs = {
                event: event,
                type: type,
                target: '_blank',
                url: url,
              }
              handleClickEvent(args)
            }}
          >
            <span
              className={socialShare('IconWrapper', {
                variant: camelCase(variant),
              })}
            >
              <Icon
                icon={type}
                className={socialShare('Icon', {
                  variant: camelCase(variant),
                  type: camelCase(type),
                })}
              />
            </span>
          </Link>
        )
      })}
    </div>
  )
}
