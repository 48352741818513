import { IconSocialShare } from './iconSocialShare'
import { LogoSocialShare } from './logoSocialShare'
import { SocialShareProps } from './type'

export const SocialShare = (props: SocialShareProps) => {
  const { variant } = props

  return variant === 'image' ? (
    <LogoSocialShare {...props} />
  ) : (
    <IconSocialShare {...props} />
  )
}
