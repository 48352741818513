import camelCase from 'lodash/camelCase'

import { EventType, GTM, ISocialShareData } from '@scm/tag-manager'
import { Image } from '@scm/ui-component-image'
import { Link } from '@scm/ui-component-link'

import { socialShare } from './socialShare.constants'
import { ClickEventArgs, LogoSocialShareProps } from './type'

export const LogoSocialShare = ({
  className,
  variant,
  classNameLink,
  logos,
  location,
}: LogoSocialShareProps) => {
  if (!logos || !logos.length) return null

  const sendEvent = GTM.hooks.useSendEvent()
  const handleClickEvent = ({ event, type, target, url }: ClickEventArgs) => {
    event.preventDefault()
    const payload: ISocialShareData = {
      name: type,
      location: location,
    }
    sendEvent({
      data: payload,
      event: EventType.SOCIAL_SHARE,
      _clear: true,
    })
    window.open(url, target)
    return false
  }

  return (
    <div
      data-component="social-share"
      className={socialShare(
        { variant: camelCase(variant), location: camelCase(location) },
        [className],
      )}
    >
      {logos.map(({ title, url, openInNewTab, logoAlt, logoUrl }, index) => {
        if (url === '#') return null

        return (
          // eslint-disable-next-line jsx-a11y/anchor-is-valid
          <Link
            to={url}
            key={index}
            target={openInNewTab ? '_blank' : '_self'}
            aria-label={title}
            className={classNameLink}
            onClick={event => {
              const args: ClickEventArgs = {
                event: event,
                type: title,
                target: openInNewTab ? '_blank' : '_self',
                url: url,
              }
              handleClickEvent(args)
            }}
            rel="noreferrer"
          >
            <Image
              src={logoUrl}
              alt={logoAlt}
              className={socialShare('Image')}
              fallback="brand"
            />
          </Link>
        )
      })}
    </div>
  )
}
